/* You can add global styles to this file, and also import other style files */
@import "assets/scss/colors";
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Montserrat:ital,wght@0,100;0,200;0,400;0,500;0,600;0,700;0,800;1,100&family=Poppins:ital,wght@0,100;0,200;0,300;0,600;0,700;1,100;1,200;1,600;1,700;1,900&display=swap');
body {
  font-style: normal;
  font-family: 'DM Sans', sans-serif;
  h1,h2,h3 {
    color: $black;
  }
  p ,a {
    font-family: 'Poppins', sans-serif;
    color: $light-grey;
  }
}
.section-margin {
  margin: 4em auto;
}

.dropdown-item:hover, .dropdown-item:active{
  background: none !important;
}
#breadcrumb {
  ul {
    margin: 0 !important;
  }
}
span.line {
  color: $red;
}

.content-points {
  position: relative;
  left: -150px;
  top: -20px;
}
.content {
  position: relative;
  top: -70px;
}
.slick-dots li.slick-active button:before {
  color: black !important;
  font-size: 16px;
  opacity: 0.75;
}

.slick-dots li button:before {
  color: $blue !important;
  font-size: 16px !important;
  opacity : 1 !important;
}

.slick-dots {
  text-align: left !important;
}

@media screen and (max-width: 992px) {
  .slick-dots {
    text-align: center !important;
  }
}
.btn-news {
  padding: 18px 36px;
  display: inline-block;
  font-size: 14px;
  letter-spacing: 1px;
  cursor: pointer;
  box-shadow: inset 0 0 0 0 $blue;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
}
.btn-news:hover {
  box-shadow: inset 400px 0 0 0 $blue;
}

.row-bootstrap {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.row-bootstrap > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.quickfix-titre-margin{
  margin-bottom: 5rem;
}


/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

